import Vue from 'vue';
import App from './App.vue';
import router from './router';
import ElementUI from 'element-ui';
// 引入element
import 'element-ui/lib/theme-chalk/index.css';
import './assets/css/style.css';
// 禁止浏览器缩放
// import './assets/js/jinzi.js';

// 在vue中使用elementui
Vue.use(ElementUI);
Vue.config.productionTip = false

/* 路由发生变化修改页面title*/
router.beforeEach((to,form,next) => {
  if(to.meta.title) {
    document.title = to.meta.title
  }
  document.body.scrollTop = 0;
  document.documentElement.scrollTop = 0;
  // 兼容IE
  window.scrollTo(0,0);
  next()
  // if (to.meta.requiresAuth) {
  //   if (getToken()) {
  //     next()
  //   } else {
  //     next('/?r=' + to.path)
  //   }
  // }else {
  //   next()
  // }
})

new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
