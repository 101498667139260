import Vue from 'vue'
import VueRouter from 'vue-router'
// import HomeView from '@/views/HomeView'

Vue.use(VueRouter)

const routes = [
    {
        path:'/',
        name:'home',
        // component: HomeView,
        component: () => import('../views/HomeView.vue'),
        meta:{title:'济宁中安职业培训学校有限责任公司',requiresAuth:false}
    },{
        path:'/login',
        name:'loginView',
        // component: HomeView,
        component: () => import('../views/login/login.vue'),
        meta:{title:'会员登录-济宁中安职业培训学校有限责任公司',requiresAuth:false}
    },{
        path:'/register',
        name:'registerView',
        // component: HomeView,
        component: () => import('../views/login/register.vue'),
        meta:{title:'注册账号-济宁中安职业培训学校有限责任公司',requiresAuth:false}
    },{
        path:'/sxjlb',
        name:'ViewOne',
        // component: HomeView,
        component: () => import('../views/lists/ViewOne.vue'),
        meta:{title:'济宁中安职业培训学校有限责任公司',requiresAuth:false}
    },{
        path:'/cpfw',
        name:'ViewTwo',
        // component: HomeView,
        component: () => import('../views/lists/ViewTwo.vue'),
        meta:{title:'济宁中安职业培训学校有限责任公司',requiresAuth:false}
    },{
        path:'/qygk',
        name:'ViewThree',
        // component: HomeView,
        component: () => import('../views/lists/ViewThree.vue'),
        meta:{title:'济宁中安职业培训学校有限责任公司',requiresAuth:false}
    },{
        path:'/tdhx',
        name:'ViewFour',
        // component: HomeView,
        component: () => import('../views/lists/ViewFour.vue'),
        meta:{title:'济宁中安职业培训学校有限责任公司',requiresAuth:false}
    },{
        path:'/rczp',
        name:'ViewFive',
        // component: HomeView,
        component: () => import('../views/lists/ViewFive.vue'),
        meta:{title:'济宁中安职业培训学校有限责任公司',requiresAuth:false}
    }
]

const router = new VueRouter({
    mode:'history',
    base:process.env.BASE_URL,
    routes
})

export default router